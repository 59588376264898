<template>
  <div>
    <!-- <alert v-if="alert" dense text :type="typestatus">{{textInfo}}</alert> -->
    <div v-if="alert" class="alert alert-warning" role="alert"> {{textInfo}} </div>
  </div>
</template>

<script>
export default {
  name: "warningAlert",
  data() {
    return {};
  },
  mounted() {},
  props: {
    textInfo: {
      type: String
    },
    typestatus: {
      type: String
    },
    alert: {
      type: Boolean
    }
  }
};
</script>