import Switcher from "@/components/switcher";
import {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    HomeIcon,
    UserIcon,
    KeyIcon
} from "vue-feather-icons";

import Vue from "vue";
import axios from "axios";
import { EventBus } from "@/event-bus";

import warnningComponent from "../../../components/warningAlert.vue";

import infoService from "@/services/infoService";

/**
 * Auth-login component
 */
export default {
    data() {
        return {
            valid: true,
            email: null,
            password: null,

            textWarning: "",
            typeWarning: "",
            showWarnnig: false,
            organizations: [],
            showPicker: false,
            selectedUser: null,
            overlay: false,
            checkEmail: false,
        };
    },
    components: {
        Switcher,
        FacebookIcon,
        InstagramIcon,
        TwitterIcon,
        LinkedinIcon,
        HomeIcon,
        UserIcon,
        KeyIcon,
        warnningComponent
    },
    mounted() {
        let token = this.$cookies.get("user_token") || null;
        if (token != null) {
            this.$router.replace({
                path: "/home"
            }).catch(err => {
                // do nothing
            })
        }
    },
    methods: {
        goToLogin() {
            this.$router.replace({
                path: "/login"
            }).catch(err => {
                // do nothing
            })
        },
        warnnig(text, type) {
            (this.textWarning = text),
                (this.typeWarning = type),
                (this.showWarnnig = true);
        },
        onSelectUser() {
            if (this.selectedUser != null) {
                this.sendemail(this.selectedUser)
            }
        },
        onCancel() {
            this.selectedUser = null
            this.overlay = false
        },
        identifyCompany() {
            this.overlay = true
            axios
                .post(`/account/tula/login/company/admin`, {
                    username: this.email
                })
                .then(resp => {
                    if (resp.status == 200) {
                        if (resp.data.status == true && resp.data.result.length > 0) {
                            if (resp.data.result.length > 1) {
                                // show organization picker
                                // companyData = resp.data.result[1]
                                this.organizations = resp.data.result
                                this.showPicker = true
                            } else {
                                let companyData = resp.data.result[0] || null;
                                this.sendemail(companyData);
                            }

                        } else {
                            this.warnnig("ไม่พบผู้ใช้", "warning");
                            this.overlay = false
                        }
                    }
                })
                .catch(err => {
                    this.overlay = false
                    console.error(err);
                })
        },
        sendemail(companyData) {
            this.overlay = true
            const params = {
                iduser: companyData._id,
            };
            axios
                .put(`/account/manage/forgetpassword/admin`, params)
                .then(resp => {
                    if (resp.status == 200 && resp.data.status == true) {
                        this.checkEmail = true
                        this.overlay = false
                    } else {
                        this.overlay = false
                        this.warnnig("อีเมลไม่ถูกต้อง", "warning");
                    }
                })
                .catch(err => {
                    this.overlay = false
                    // error handler
                });
        },

    }
};